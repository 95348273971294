import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import { KokattoInboxServiceClient } from "@/serviceClients/KokattoInboxServiceClient";
import { KokattoGetInboxMediaRequest } from "@/serviceClients/requests/KokattoGetInboxMediaRequest";
import { isKokattoErrorResponse } from "@/serviceClients/responses/KokattoErrorResponse";
import { KokattoGetInboxMediaResponse } from "@/serviceClients/responses/KokattoGetInboxMediaResponse";
import store from "@/store";

const audioFileTypes = ["audio/aac", "audio/mp4", "audio/amr", "audio/mpeg"];
const imageFileTypes = ["image/jpeg", "image/png"];
const videoFileTypes = ["video/mp4", "video/3gpp"];
export const obtainInboxImage = (url: string) => {
  if (
    url.startsWith("https://api-whatsapp.kata.ai/v1/media/") ||
    url.startsWith("https://whatsapp.kata.ai/v1/media/") ||
    url.includes("kokatto.com") ||
    url.includes("kokatto.net") ||
    url.includes("https://graph.facebook.com") ||
    url.includes("undefined/media")
  ) {
    return "https://s3.amazonaws.com/FringeBucket/image_placeholder.png";
  } else {
    return url;
  }
};

export const downloadInboxImage = async (
  $event: any,
  messageObj: any,
  token: string
) => {
  if (
    $event.startsWith(
      "https://s3.amazonaws.com/FringeBucket/image_placeholder.png"
    )
  ) {
    const request: KokattoGetInboxMediaRequest = {
      media: messageObj.url,
    };
    console.log("loading image...");
    const response =
      await RefreshableRequestHelper.requestKokatto<KokattoGetInboxMediaResponse>(
        () => {
          const kokattoInboxServiceClient = new KokattoInboxServiceClient({
            token: store.getters.getKokattoTokenAccess,
          });
          return kokattoInboxServiceClient.getInboxMedia(request);
        }
      );

    if (!isKokattoErrorResponse(response)) {
      if (response.url) {
        messageObj.url = response.url;
      } else {
        messageObj.url = response.image;
      }
    }

    return messageObj.url;
  }
};

export const downloadInboxMedia = async (
  token: string,
  media: string,
  mime?: string,
  type?: string,
  saveAs?: string
) => {
  const request: KokattoGetInboxMediaRequest = {
    media: media,
    mime: mime,
    type: type,
  };
  if (mime) {
    request.mime = mime;
  }
  if (type) {
    request.type = type;
  }
  if (saveAs) {
    request.s = saveAs;
  }

  console.log("loading video...");
  const response =
    await RefreshableRequestHelper.requestKokatto<KokattoGetInboxMediaResponse>(
      () => {
        const kokattoInboxServiceClient = new KokattoInboxServiceClient({
          token: store.getters.getKokattoTokenAccess,
        });
        return kokattoInboxServiceClient.getInboxMedia(request);
      }
    );

  let mediaObj: any = {
    mediaUrl: "",
    mediaLoaded: false,
  };
  if (!isKokattoErrorResponse(response)) {
    if (response.url) {
      mediaObj.mediaUrl = response.url;
      mediaObj.mediaLoaded = true;
    } else if (response.image) {
      mediaObj.mediaUrl = response.image;
      const length = mediaObj.mediaUrl.toString().length;
      const lastCharOfUrl = length - 20;
      mediaObj.mediaLoaded = mediaObj.mediaUrl.substring(lastCharOfUrl, 20);
    } else {
      mediaObj = null;
    }
  } else {
    mediaObj = null;
  }

  return mediaObj;
};

export const fileSizeFormatter = (x: any) => {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const convertBytesToMegaBytes = (bytes: number): number => {
  const convertedValue = bytes / (1024 * 1000);

  return parseFloat(convertedValue.toFixed(2));
};

export const getWhatsappFileType = (selectedFile: File) => {
  if (selectedFile) {
    if (imageFileTypes.includes(selectedFile.type)) {
      return "image";
    } else if (audioFileTypes.includes(selectedFile.type)) {
      return "audio";
    } else if (videoFileTypes.includes(selectedFile.type)) {
      return "video";
    } else return "document";
  }
  return "";
};

// Converts the selected image file into base64
export const createImagePreview = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};
