export type KokattoErrorResponse = {
  statusCode?: number;
  error?: string;
  message?: string;
  status?: string;
  reason?: string;
  statusMessage?: string;
  meta?: Record<string, unknown>;
};

export function isKokattoErrorResponse(
  response: any
): response is KokattoErrorResponse {
  return (response as KokattoErrorResponse).reason !== undefined;
}

/**
 * * Specifically Check Error from CIS
 * * CIS Only have statusCode, error, message - No Attribute "reason"
 * @param response
 * @returns
 */
export function isCISErrorResponse(
  response: any
): response is KokattoErrorResponse {
  return !!(response as KokattoErrorResponse).error;
}
