import { AbilityDataModel } from "@/models/AbilityDataModel";
import { AbilityDefinitionModel } from "@/models/AbilityDefinitionModel";
import { PermissionModel } from "@/models/PermissionModel";
import { AbilityBuilder, Ability } from "@casl/ability";

export class AbilityHelper {
  private static instance: AbilityHelper;

  public static getInstance(): AbilityHelper {
    if (AbilityHelper.instance == null || AbilityHelper.instance == undefined) {
      AbilityHelper.instance = new AbilityHelper();
      return AbilityHelper.instance;
    } else {
      return AbilityHelper.instance;
    }
  }

  attachAbility(data: AbilityDataModel, abilityModel: AbilityDefinitionModel) {
    const { can, cannot, rules } = new AbilityBuilder(Ability);
    const baseCountry = abilityModel.accountCountry
      ? abilityModel.accountCountry
      : "ID";

    /**
     * * Fallback to old logic using Role (ADC USC)
     */
    if (!abilityModel.featuresAccessPermissions) {
      switch (abilityModel.role) {
        case "OWN":
          for (const item of data.allPermissions) {
            if (
              !item.allowedCountries ||
              item.allowedCountries.includes(baseCountry)
            ) {
              can(item.action, item.target);
            }
          }
          break;
        case "ADC":
          for (const item of data.allPermissions) {
            if (
              !item.allowedCountries ||
              item.allowedCountries.includes(baseCountry)
            ) {
              can(item.action, item.target);
            }
          }

          for (const cant of data.adminNegativePermissions) {
            cannot(cant.action, cant.target);
          }

          break;
        case "USC":
          for (const item of data.customerServicePermissions) {
            if (
              !item.allowedCountries ||
              item.allowedCountries.includes(baseCountry)
            ) {
              can(item.action, item.target);
            }
          }
          break;
        default:
          break;
      }
      return rules;
    }

    /**
     * * New Logic Using Client Features Access and User Features Access
     * * Should have userFeaturesAccess applied to the account/user
     *
     * ! RULES :
     * * An access/permission will be given IF it fulfill these criteria :
     * *  1. Partner Level - Exist on `allPermission` array in ability.ts (MANDATORY)
     * *  2. User/RoleAccess Level - permission in `userFeaturesPermission` on index `x` should return truthy. (MANDATORY with exception)
     * *      `x` is index initialize in `userPermissionIndex` attributes on `allPermission` array
     * *  3. Client Level - permission in `featuresPermission` on index `x` should return truthy. (OPTIONAL)
     * *      `x` is index initialize in `clientPermissionIndex` attributes on `allPermission` array
     * *  4. Country Level - Account's country is present in `allowedCountries` attributes on `allPermission` array (OPTIONAL)
     *
     * ? EXCEPTION :
     * ? The rule can be ommited explicitly (bypass) to certain access by initializing attributes `commonAccess = true` (access will be given globally)
     */

    if (abilityModel.featuresAccessPermissions) {
      for (const permisson of data.allPermissions) {
        // console.log("Grant => ", permisson);
        if (permisson.commonAccess === true) {
          can(permisson.action, permisson.target);
          continue;
        } else if (
          !(
            permisson.clientPermissionIndex ||
            permisson.clientPermissionIndex === 0
          ) &&
          !(
            permisson.userPermissionIndex || permisson.userPermissionIndex === 0
          ) &&
          !(permisson.allowedCountries && permisson.allowedCountries.length)
        ) {
          continue;
        }

        // * CLIENT Level
        if (
          permisson.clientPermissionIndex ||
          permisson.clientPermissionIndex === 0
        ) {
          if (
            !abilityModel.featuresPermissions ||
            abilityModel.featuresPermissions.charAt(
              permisson.clientPermissionIndex
            ) != "1"
          ) {
            continue;
          }
        }

        // * COUNTRY Level
        if (permisson.allowedCountries && permisson.allowedCountries.length) {
          if (!permisson.allowedCountries.includes(baseCountry)) {
            continue;
          }
        }

        // * USER/FEATURES Level
        if (
          permisson.userPermissionIndex ||
          permisson.userPermissionIndex === 0
        ) {
          if (
            abilityModel.featuresAccessPermissions.charAt(
              permisson.userPermissionIndex
            ) != "1"
          ) {
            continue;
          }
        }
        can(permisson.action, permisson.target);
      }
    }
    console.log("ADR");
    console.log(rules);
    return rules;
  }
}
