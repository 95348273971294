import { KokattoServiceClient } from "./KokattoServiceClient";
import { KokattoErrorResponse } from "./responses/KokattoErrorResponse";
import axios from "axios";
import {
  KokattoGetInboxResponse,
  KokattoGetUnreadChatroom,
} from "./responses/KokattoGetInboxResponse";
import {
  KokattoPaginatedRequest,
  KokattoUnreadChatroomRequest,
} from "./requests/KokattoPaginatedRequest";
import { KokattoForwardInboxRequest } from "./requests/KokattoForwardInboxRequest";
import { KokattoForwardInboxResponse } from "./responses/KokattoForwardInboxResponse";
import { KokattoResolveInboxRequest } from "./requests/KokattoResolveInboxRequest";
import { KokattoResolveInboxResponse } from "./responses/KokattoResolveInboxResponse";
import { KokattoGetChatsInboxRequest } from "./requests/KokattoGetChatsInboxRequest";
import { KokattoGetChatsInboxResponse } from "./responses/KokattoGetChatsInboxResponse";
import {
  KokattoGetInboxMediaRequest,
  KokattoDownloadMultipleAttachmentRequest,
} from "./requests/KokattoGetInboxMediaRequest";
import {
  KokattoGetInboxMediaResponse,
  KokattoMultipleAttachmentResponse,
} from "./responses/KokattoGetInboxMediaResponse";
import { KokattoSendMessageRequest } from "./requests/KokattoSendMessageRequest";
import { KokattoSendMessageResponse } from "./responses/KokattoSendMessageResponse";
import { KokattoGetChatHistoryRequest } from "./requests/KokattoGetChatHistoryRequest";
import { KokattoGetChatHistoryResponse } from "./responses/KokattoGetChatHistoryResponse";
import { KokattoGetFileLinkRequest } from "./requests/KokattoGetFileLinkRequest";
import { KokattoGetFileLinkResponse } from "./responses/KokattoGetFileLinkResponse";
import { KokattoGetImageFromUrlRequest } from "../serviceClients/requests/KokattoGetImageFromUrlRequest";
import { KokattoGetImageFromUrlResponse } from "../serviceClients/responses/KokattoGetImageFromUrlResponse";
import { KokattoUpdateChatPriorityRequest } from "./requests/KokattoUpdateChatPriorityRequest";
import { KokattoGetChatByIdRequest } from "./requests/KokattoGetChatByIdRequest";
import { KokattoSingleChatResponse } from "./responses/KokattoSingleChatResponse";
import { KokattoDeleteChatResponse } from "./responses/KokattoDeleteChatResponse";
import { KokattoGetCatalogItemResponse } from "./responses/KokattoGetCatalogItemResponse";
import { KokattoGetTicketDetailsResponse } from "./responses/KokattoGetTicketDetailsResponse";
import { KokattoChangeStatusChatroomRequest } from "./requests/KokattoChangeStatusChatroomRequest";
import { KokattoBaseResponse } from "./responses/KokattoBaseResponse";
import {
  ChatRoomUpdateTicketRequest,
  KokattoGetTicketFormRequest,
} from "./requests/KokattoGetTicketFormRequest";
import { KokattoUpdateTicketFieldResponse } from "./responses/KokattoUpdateTicketFieldResponse";
import { KokattoGetChatroomByIdResponse } from "./responses/KokattoGetChatroomByIdResponse";
import { KokattoCreateTicketRequest } from "./requests/KokattoCreateTicketRequest";
import { KokattoUpdateTicketRequest } from "./requests/KokattoUpdateTicketRequest";
import { KokattoCreateTicketResponse } from "./responses/KokattoCreateTicketReponse";
import { KokattoUpdateTicketResponse } from "./responses/KokattoUpdateTicketResponse";
import { KokattoGetMacrosListRequest } from "./requests/KokattoGetMacrosListRequest";
import { KokattoGetMacrosListResponse } from "./responses/KokattoGetMacrosListResponse";

export class KokattoInboxServiceClient extends KokattoServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_KOKATTO_PORTAL ?? "";
    }
  }

  async getCountUnreadChatroomPerChannel(
    request: KokattoUnreadChatroomRequest
  ): Promise<KokattoGetUnreadChatroom | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/unread/count`;
    // const url = `https://portal-beta.kokatto.net/api/v1/csplatform/chatRoom/unread/count`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      return response.data;
    });
  }

  async getInboxUnassigned(
    request: KokattoPaginatedRequest
  ): Promise<KokattoGetInboxResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/bot`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getInboxAssigned(
    request: KokattoPaginatedRequest
  ): Promise<KokattoGetInboxResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async postNewChat(payload: any) {
    const url = `${this.baseUrl}/csplatform/chatRoom`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, {
        ...payload,
      });
      return response.data;
    });
  }

  async forwardUnassignedInbox(
    request: KokattoForwardInboxRequest
  ): Promise<KokattoForwardInboxResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/bot/transfer`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async forwardAssignedInbox(
    request: KokattoForwardInboxRequest
  ): Promise<KokattoForwardInboxResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/transfer`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async resolveUnassignedInbox(
    request: KokattoResolveInboxRequest
  ): Promise<KokattoResolveInboxResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/bot`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.put(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async resolveAssignedInbox(
    request: KokattoResolveInboxRequest,
    forceClose?: boolean
  ): Promise<KokattoResolveInboxResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom`;
    const req = {
      data: request,
      params: {
        forceClose,
      },
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    };
    return this.failsafeHttpRequest(async () => {
      const response = await axios.delete(url, req);
      return response.data;
    });
  }

  async getChatsInbox(
    request: KokattoGetChatsInboxRequest
  ): Promise<KokattoGetChatsInboxResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chat`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getInboxMedia(
    request: KokattoGetInboxMediaRequest
  ): Promise<KokattoGetInboxMediaResponse | KokattoErrorResponse> {
    return this.failsafeHttpRequest(async () => {
      const url = `${this.baseUrl}/csplatform/image`;
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getFileLink(
    request: KokattoGetFileLinkRequest
  ): Promise<KokattoGetFileLinkResponse | KokattoErrorResponse> {
    return this.failsafeHttpRequest(async () => {
      const url = `${this.baseUrl}/csplatform/upload/getLink`;
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async uploadFileLink(
    url: string,
    file: any
  ): Promise<any | KokattoErrorResponse> {
    return this.failsafeHttpRequest(async () => {
      const response = await axios.put(url, file, {
        headers: {
          "content-type": file.type,
        },
      });
      return response.data;
    });
  }

  async sendMessage(
    request: KokattoSendMessageRequest
  ): Promise<KokattoSendMessageResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chat/secure`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getChatHistory(
    request: KokattoGetChatHistoryRequest
  ): Promise<KokattoGetChatHistoryResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/history`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getChatroomHistory(
    customerPhoneNumber: string,
    request: KokattoGetChatHistoryRequest
  ): Promise<KokattoGetChatHistoryResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/history`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(
        url,
        {
          customerPhoneNumber: customerPhoneNumber,
        },
        {
          headers: {
            authorization: `Bearer ${this.token}`,
          },
          params: request,
        }
      );
      return response.data;
    });
  }

  async getBase64ImageFromUrl(
    request: KokattoGetImageFromUrlRequest
  ): Promise<KokattoGetImageFromUrlResponse | KokattoErrorResponse> {
    let mimeType = "";
    if (request.mime) {
      mimeType += `&mime=${request.mime}`;
    }

    if (request.type) {
      mimeType += `&type=${request.type}`;
    }

    if (request.saveAs) {
      request.saveAs = `&s=${request.saveAs}`;
    } else {
      request.saveAs = "";
    }

    const url = encodeURI(
      `${this.baseUrl}/csplatform/image?media=${request.imageUrl}${mimeType}${request.saveAs}`
    );

    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async attachCategoryToChatroom(
    chatroomId: string,
    categoryId: string
  ): Promise<KokattoResolveInboxResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/category?id=${chatroomId}&categoryId=${categoryId}`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, null, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async updateChatroomPriority(
    request: KokattoUpdateChatPriorityRequest
  ): Promise<KokattoResolveInboxResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/priority`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getChatByMessageId(
    request: KokattoGetChatByIdRequest
  ): Promise<KokattoSingleChatResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chat/id`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async downloadMultipleAttachment(
    request: KokattoDownloadMultipleAttachmentRequest
  ): Promise<KokattoMultipleAttachmentResponse | KokattoErrorResponse> {
    return this.failsafeHttpRequest(async () => {
      const url = `${this.baseUrl}/csplatform/image/multiAttachment`;
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async deleteChat(
    id: string
  ): Promise<KokattoDeleteChatResponse | KokattoErrorResponse> {
    return this.failsafeHttpRequest(async () => {
      const url = `${this.baseUrl}/csplatform/chat/secure`;
      const response = await axios.delete(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        data: {
          id: id,
        },
      });
      return response.data;
    });
  }

  async getCatalogItemByRetailerId(
    retailerId: string
  ): Promise<KokattoGetCatalogItemResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/product-catalog/items/by-retailerid/${retailerId}`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getTicketDetails(
    ticketId: string
  ): Promise<KokattoGetTicketDetailsResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/tickets/${ticketId}`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async changeChatroomStatus(
    chatroomId: string,
    request: KokattoChangeStatusChatroomRequest
  ): Promise<KokattoBaseResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/status?id=${chatroomId}`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.put(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  /**
   * Update ticket with updated ticket field
   */
  async updateTicket(
    params: KokattoGetTicketFormRequest,
    request: ChatRoomUpdateTicketRequest
  ): Promise<KokattoUpdateTicketFieldResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/tickets/update`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.put(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: params,
      });
      return response.data;
    });
  }

  /**
   * Get chatroom by id
   */
  async getChatRoomById(
    id: string
  ): Promise<KokattoGetChatroomByIdResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatRoom/detail`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: {
          id: id,
        },
      });
      return response.data;
    });
  }

  /**
   * POST request to execute Manual Ticket Creation
   */
  async postCreateTicket(
    request: KokattoCreateTicketRequest
  ): Promise<KokattoCreateTicketResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/tickets`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  /**
   * PUT request to execute Ticket Attributes Update
   */
  async putUpdateTicket(
    request: KokattoUpdateTicketRequest
  ): Promise<KokattoUpdateTicketResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/tickets`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.put(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getMacrosList(
    request: KokattoGetMacrosListRequest
  ): Promise<KokattoGetMacrosListResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/macros`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: request,
      });
      return response.data;
    });
  }
}
