import Vue from "vue";
import Vuex from "vuex";
import Auth from "@/store/modules/Auth";
import Cart from "@/store/modules/Cart";
import Variant from "@/store/modules/Variant";
import VuexPersistence from "vuex-persist";
import Wallet from "@/store/modules/Wallet";
import Account from "@/store/modules/Account";
import sharedMutations from "vuex-shared-mutations";
import StoreInfo from "@/store/modules/StoreSetting";
import MainOverlay from "@/store/modules/MainOverlay";
import OnboardingState from "@/store/modules/OnboardingState";
import CustomerService from "@/store/modules/CustomerService";
import GlobalConfiguration from "@/store/modules/GlobalConfiguration";
import TemplateConfiguration from "@/store/modules/TemplateConfiguration";
import Broadcast from "@/store/modules/Broadcast";
import WebsocketState from "@/store/modules/WebsocketState";
import CallCenter from "@/store/modules/CallCenter";
import ChatRoom from "@/store/modules/ChatRoom";
import TicketView from "@/store/modules/TicketView";
import TicketChatListCustomHeader from "@/store/modules/TicketChatListCustomHeader";
import TimeTracker from "@/store/modules/TimeTracker";
import CustomerServicePlayMode from "@/store/modules/CustomerServicePlayMode";
import TicketForm from "./modules/TicketForm";
import Macros from "@/store/modules/Macros";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: [
    "Auth",
    "Cart",
    "Wallet",
    "Account",
    "Variant",
    "StoreInfo",
    "MainOverlay",
    "OnboardingState",
    "CustomerService",
    "GlobalConfiguration",
    "TemplateConfiguration",
    "Broadcast",
    "WebsocketState",
    "CallCenter",
    "CustomerServicePlayMode",
    "TicketChatListCustomHeader",
  ],
});

export default new Vuex.Store({
  modules: {
    Auth,
    Cart,
    Wallet,
    Account,
    Variant,
    StoreInfo,
    TicketForm,
    MainOverlay,
    OnboardingState,
    CustomerService,
    GlobalConfiguration,
    TemplateConfiguration,
    Broadcast,
    WebsocketState,
    CallCenter,
    ChatRoom,
    TicketView,
    TicketChatListCustomHeader,
    TimeTracker,
    CustomerServicePlayMode,
    Macros,
  },
  plugins: [
    vuexLocal.plugin,
    sharedMutations({ predicate: ["updateSharedLoginEmail"] }),
  ],
});
