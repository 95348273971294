import {
  isKokattoErrorResponse,
  KokattoErrorResponse,
} from "@/serviceClients/responses/KokattoErrorResponse";
import { KokattoAuthServiceClient } from "@/serviceClients/KokattoAuthServiceClient";
import store from "@/store";
import { KokattoRefreshTokenRequest } from "@/serviceClients/requests/KokattoRefreshTokenRequest";
import { isKokattoLoginResponse } from "@/serviceClients/responses/KokattoLoginResponse";
import router from "@/router";
import {
  isKoalaErrorResponse,
  KoalaErrorResponse,
} from "@/serviceClients/responses/KoalaErrorResponse";
import { KoalaAuthServiceClient } from "@/serviceClients/KoalaAuthServiceClient";
import { KoalaRefreshAccessRequest } from "@/serviceClients/requests/KoalaRefreshAccessRequest";
import {
  isKoalaLoginResponse,
  isKoalaLoginSuccessResponse,
} from "@/serviceClients/responses/KoalaLoginResponse";
import { ProcatErrorResponse } from "@/serviceClients/responses/ProcatErrorResponse";

export abstract class RefreshableRequestHelper {
  // public static async request<T>(
  //   refreshableRequest: () => Promise<T> | Promise<KokattoErrorResponse>
  // ): Promise<T | KokattoErrorResponse> {
  //   const response = await refreshableRequest();
  //   if (isKokattoErrorResponse(response)) {
  //     if (response.statusCode == 401) {
  //       const kokattoAuthServiceClient = new KokattoAuthServiceClient({
  //         token: store.getters.getKoalaTokenAccess,
  //       });
  //       const authRequest: KokattoRefreshTokenRequest = {
  //         refreshToken: store.getters.getKokattoTokenRefresh,
  //       };
  //       const authResponse = await kokattoAuthServiceClient.refreshToken(
  //         authRequest
  //       );
  //       if (isKokattoLoginResponse(authResponse)) {
  //         store.dispatch("login", authResponse);
  //         return refreshableRequest();
  //       } else {
  //         if (authResponse.statusCode == 403) {
  //           store.dispatch("clearToken");
  //           router.push("/login");
  //         }
  //
  //         return response;
  //       }
  //     } else {
  //       return response;
  //     }
  //   } else {
  //     return response;
  //   }
  // }

  public static async requestKokatto<T>(
    refreshableRequest: () => Promise<T> | Promise<KokattoErrorResponse>
  ): Promise<T | KokattoErrorResponse> {
    const response = await refreshableRequest();
    return response;
    // if (isKokattoErrorResponse(response)) {
    //   if (response.statusCode == 401) {
    //     const authServiceClient = new KoalaAuthServiceClient({
    //       token: store.getters.getKoalaTokenAccess,
    //     });
    //
    //     const refreshRequest: KoalaRefreshAccessRequest = {
    //       koalaRefreshToken: store.getters.getKoalaTokenRefresh,
    //       kokattoAccessToken: store.getters.getKokattoTokenAccess,
    //       kokattoRefreshToken: store.getters.getKokattoTokenRefresh,
    //     };
    //
    //     const authResponse = await authServiceClient.koalaPlusRefreshAccess(
    //       refreshRequest
    //     );
    //
    //     console.log("authResponse ::", isKoalaLoginResponse(authResponse));
    //     console.log("authResponse ::", authResponse);
    //
    //     if (isKoalaLoginResponse(authResponse)) {
    //       await store.dispatch("storeTokenKokatto", authResponse);
    //       await store.dispatch("storeTokenKoala", authResponse);
    //       return refreshableRequest();
    //     } else {
    //       if (authResponse.statusCode == 401) {
    //         store.dispatch("clearToken");
    //         router.push("/login");
    //       }
    //
    //       return response;
    //     }
    //   } else {
    //     return response;
    //   }
    // } else {
    //   return response;
    // }
  }

  public static async requestKoalaPlus<T>(
    refreshableRequest: () => Promise<T> | Promise<KoalaErrorResponse>
  ): Promise<T | KoalaErrorResponse> {
    const response = await refreshableRequest();
    return response;

    // if (isKoalaErrorResponse(response)) {
    //   if (response.statusCode == 401) {
    //     const authServiceClient = new KoalaAuthServiceClient({
    //       token: store.getters.getKoalaTokenAccess,
    //     });
    //
    //     const refreshRequest: KoalaRefreshAccessRequest = {
    //       koalaRefreshToken: store.getters.getKoalaTokenRefresh,
    //     };
    //
    //     const authResponse = await authServiceClient.koalaPlusRefreshAccess(
    //       refreshRequest
    //     );
    //
    //     if (isKoalaLoginSuccessResponse(authResponse)) {
    //       await store.dispatch("storeTokenKoala", authResponse);
    //       return refreshableRequest();
    //     } else {
    //       if (authResponse.statusCode == 401) {
    //         store.dispatch("clearToken");
    //         router.push("/login");
    //       }
    //       return response;
    //     }
    //   } else {
    //     return response;
    //   }
    // } else {
    //   return response;
    // }
  }

  public static async requestKoala<T>(
    refreshableRequest: () => Promise<T> | Promise<any>
  ): Promise<T | KoalaErrorResponse> {
    const response = await refreshableRequest();
    return response;
    console.log("requestKoala :: ", response);

    // if (isKoalaErrorResponse(response)) {
    //   if (response.statusCode == 401) {
    //     const authServiceClient = new KoalaAuthServiceClient({
    //       token: store.getters.getKoalaTokenAccess,
    //     });
    //
    //     const refreshRequest: KoalaRefreshAccessRequest = {
    //       koalaRefreshToken: store.getters.getKoalaTokenRefresh,
    //     };
    //
    //     const authResponse = await authServiceClient.koalaPlusRefreshAccess(
    //       refreshRequest
    //     );
    //
    //     if (isKoalaLoginSuccessResponse(authResponse)) {
    //       await store.dispatch("storeTokenKoala", authResponse);
    //       return refreshableRequest();
    //     } else {
    //       if (authResponse.statusCode == 401) {
    //         store.dispatch("clearToken");
    //         router.push("/login");
    //       }
    //       return response;
    //     }
    //   } else {
    //     return response;
    //   }
    // } else {
    //   return response;
    // }
  }

  public static async requestKoalaThrowable<T>(
    refreshableRequest: () => Promise<T>
  ): Promise<T> {
    // eslint-disable-next-line no-useless-catch
    try {
      return await refreshableRequest();
    } catch (e: any) {
      // console.log("error", e.response);
      // // if (isKoalaErrorResponse(e)) {
      // if (e.response.status == 401) {
      //   const authServiceClient = new KoalaAuthServiceClient({
      //     token: store.getters.getKoalaTokenAccess,
      //   });
      //
      //   const refreshRequest: KoalaRefreshAccessRequest = {
      //     koalaRefreshToken: store.getters.getKoalaTokenRefresh,
      //   };
      //
      //   const authResponse = await authServiceClient.koalaPlusRefreshAccess(
      //     refreshRequest
      //   );
      //
      //   console.log("authResponse", authResponse);
      //   console.log("isKoalaLoginResponse", isKoalaLoginResponse(authResponse));
      //
      //   if (isKoalaLoginResponse(authResponse)) {
      //     await store.dispatch("storeTokenKoala", authResponse);
      //     return refreshableRequest();
      //   } else {
      //     if (authResponse.statusCode == 401) {
      //       store.dispatch("clearToken");
      //       router.push("/login");
      //     }
      //   }
      // }
      // // }
      throw e;
    }
  }

  public static async requestProcat<T>(
    refreshableRequest: () => Promise<T> | Promise<ProcatErrorResponse>
  ): Promise<T | ProcatErrorResponse> {
    const response = await refreshableRequest();
    return response;
    // if (isKoalaErrorResponse(response)) {
    //   if (response.statusCode == 401) {
    //     const authServiceClient = new KoalaAuthServiceClient({
    //       token: store.getters.getKoalaTokenAccess,
    //     });
    //
    //     const refreshRequest: KoalaRefreshAccessRequest = {
    //       procatRefreshToken: store.getters.getProductCatalogTokenRefresh,
    //     };
    //
    //     const authResponse = await authServiceClient.koalaPlusRefreshAccess(
    //       refreshRequest
    //     );
    //
    //     if (isKoalaLoginSuccessResponse(authResponse)) {
    //       await store.dispatch("storeTokenProductCatalog", authResponse);
    //       return refreshableRequest();
    //     } else {
    //       if (authResponse.statusCode == 401) {
    //         store.dispatch("clearToken");
    //         router.push("/login");
    //       }
    //       return response;
    //     }
    //   } else {
    //     return response;
    //   }
    // } else {
    //   return response;
    // }
  }

  // public static async requestKoala<T>(
  //   refreshableRequest: () => Promise<T> | Promise<KoalaErrorResponse>
  // ): Promise<T | KoalaErrorResponse> {
  //   try {
  //     return await refreshableRequest();
  //   } catch (e) {
  //     if (isKoalaErrorResponse(e)) {
  //       if (e.statusCode == 401) {
  //         const authServiceClient = new KoalaAuthServiceClient({
  //           token: store.getters.getKoalaTokenAccess,
  //         });
  //
  //         const refreshRequest: KoalaRefreshAccessRequest = {
  //           koalaRefreshToken: store.getters.getKoalaTokenRefresh,
  //         };
  //
  //         const authResponse = await authServiceClient.koalaPlusRefreshAccess(
  //           refreshRequest
  //         );
  //
  //         if (isKoalaLoginResponse(authResponse)) {
  //           await store.dispatch("storeTokenKoala", authResponse);
  //           return refreshableRequest();
  //         } else {
  //           if (authResponse.statusCode == 401) {
  //             store.dispatch("clearToken");
  //             router.push("/login");
  //           }
  //         }
  //       }
  //     }
  //     throw e;
  //   }
  // }

  // public static async requestProcat<T>(
  //   refreshableRequest: () => Promise<T> | Promise<ProcatErrorResponse>
  // ): Promise<T | ProcatErrorResponse> {
  //   try {
  //     return await refreshableRequest();
  //   } catch (e) {
  //     if (isKoalaErrorResponse(e)) {
  //       if (e.statusCode == 401) {
  //         const authServiceClient = new KoalaAuthServiceClient({
  //           token: store.getters.getKoalaTokenAccess,
  //         });
  //
  //         const refreshRequest: KoalaRefreshAccessRequest = {
  //           procatRefreshToken: store.getters.getProductCatalogTokenRefresh,
  //         };
  //
  //         const authResponse = await authServiceClient.koalaPlusRefreshAccess(
  //           refreshRequest
  //         );
  //
  //         if (isKoalaLoginResponse(authResponse)) {
  //           await store.dispatch("storeTokenProductCatalog", authResponse);
  //           return refreshableRequest();
  //         } else {
  //           if (authResponse.statusCode == 401) {
  //             store.dispatch("clearToken");
  //             router.push("/login");
  //           }
  //         }
  //       }
  //     }
  //     throw e;
  //   }
  // }
}
