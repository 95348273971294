import { brandConstants } from "@/brand/current/PartnerConstants";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import vuetify from "../../plugins/vuetify";

type SnackbarModel = {
  message: string;
  isActive: boolean;
  color: string;
};

type DialogAlertModel = {
  message: string;
  isActive: boolean;
  color: string;
};

type PriceOutboundPerCategory = {
  MARKETING: number;
  UTILITY: number;
  AUTHENTICATION: number;
};

type State = {
  priceInbound: number;
  priceOutbound: number;
  priceOutboundPerCategory: PriceOutboundPerCategory;
  loading: boolean;
  snackbar: SnackbarModel;
  snackbarTop: SnackbarModel;
  dialogAlert: DialogAlertModel;
  locale: string;
  timezone: string;
  version: string;
  selectedSideBarMenu: string;
};

const state: State = {
  priceInbound: 0,
  priceOutbound: 0,
  priceOutboundPerCategory: {
    MARKETING: 0,
    UTILITY: 0,
    AUTHENTICATION: 0,
  },
  loading: false,
  snackbar: {
    message: "",
    isActive: false,
    color: "",
  },
  snackbarTop: {
    message: "",
    isActive: false,
    color: "",
  },
  dialogAlert: {
    message: "",
    isActive: false,
    color: "",
  },
  locale:
    localStorage.getItem("locale") || brandConstants.DEFAULT_LANGUAGE || "id",
  timezone: "Asia/Jakarta",
  version: "",
  selectedSideBarMenu: "",
};

const getters: GetterTree<State, any> = {
  getPriceInbound: (state) => state.priceInbound,
  getPriceOutbound: (state) => state.priceOutbound,
  getPriceOutboundPerCategory: (state) => state.priceOutboundPerCategory,
  getCommonLoading: (state) => state.loading,
  getSnackbarMessage: (state) => state.snackbar.message,
  getSnackbarColor: (state) => state.snackbar.color,
  getIsActiveSnackbar: (state) => state.snackbar.isActive,
  getDialogAlertMessage: (state) => state.dialogAlert.message,
  getDialogAlertColor: (state) => state.dialogAlert.color,
  getDialogAlertIsActive: (state) => state.dialogAlert.isActive,
  getSnackbarTopMessage: (state) => state.snackbarTop.message,
  getSnackbarTopColor: (state) => state.snackbarTop.color,
  getIsActiveSnackbarTop: (state) => state.snackbarTop.isActive,
  getLanguage: (state) => state.locale,
  getTimezone: (state) => state.timezone,
  getVersion: (state) => state.version,
  getSelectedSideBarMenu: (state) => state.selectedSideBarMenu,
};

const mutations: MutationTree<State> = {
  updatePriceInbound(state, priceInbound: number) {
    state.priceInbound = priceInbound;
  },
  updatePriceOutbound(state, priceOutbound: number) {
    state.priceOutbound = priceOutbound;
  },
  updateCommonLoading(state, loading: boolean) {
    state.loading = loading;
  },
  updateSnackbar(state, snackbar: SnackbarModel) {
    state.snackbar = snackbar;
  },
  updatepriceOutboundPerCategory(state, price: PriceOutboundPerCategory) {
    state.priceOutboundPerCategory = price;
  },
  updateDialogAlert(state, dialogAlert: DialogAlertModel) {
    state.dialogAlert = dialogAlert;
  },
  updateSnackbarTop(state, snackbarTop: SnackbarModel) {
    state.snackbarTop = snackbarTop;
  },
  updateLanguage: (state, value: string) => {
    state.locale = value;
    localStorage.setItem("locale", value);
    vuetify.i18n.locale = value;
  },
  updateTimezone: (state, timezone: string) => {
    state.timezone = timezone;
  },
  updateVersion: (state, version: string) => {
    state.version = version;
  },
  updateSelectedSideBarMenu: (state, menu: string) => {
    state.selectedSideBarMenu = menu;
  },
};

const actions: ActionTree<State, any> = {
  setPriceInbound(context, priceInbound: number) {
    context.commit("updatePriceInbound", priceInbound);
  },
  setPriceOutbound(context, priceOutbound: number) {
    context.commit("updatePriceOutbound", priceOutbound);
  },
  clearConfiguration(context) {
    context.commit("updatePriceInbound", 0);
    context.commit("updatePriceOutbound", 0);
    context.commit("updateTimezone", "");
  },
  setCommonLoading(context, loading: boolean) {
    context.commit("updateCommonLoading", loading);
  },
  setSnackbar(context, snackbar: SnackbarModel) {
    context.commit("updateSnackbar", snackbar);
  },
  setPriceOutboundPerCategory(context, price: PriceOutboundPerCategory) {
    context.commit("updatepriceOutboundPerCategory", price);
  },
  clearSnackbar(context) {
    context.commit("updateSnackbar", {
      message: "",
      isActive: false,
      color: "",
    });
  },
  setDialogAlert(context, dialogAlert: DialogAlertModel) {
    context.commit("updateDialogAlert", dialogAlert);
  },
  clearDialogAlert(context) {
    context.commit("updateDialogAlert", {
      message: "",
      isActive: false,
      color: "",
    });
  },
  setSnackbarTop(context, snackbarTop: SnackbarModel) {
    context.commit("updateSnackbarTop", snackbarTop);
  },
  setLanguage(context, language: string) {
    context.commit("updateLanguage", language);
  },
  setTimezone(context, timezone: string) {
    context.commit("updateTimezone", timezone);
  },
  clearSnackbarTop(context) {
    context.commit("updateSnackbarTop", {
      message: "",
      isActive: false,
      color: "",
    });
  },
  setVersion(context, version: string) {
    context.commit("updateVersion", version);
  },
  setSelectedSideBarMenu(context, menu: string) {
    context.commit("updateSelectedSideBarMenu", menu);
  },
};

const GlobalConfiguration = { state, getters, mutations, actions };

export default GlobalConfiguration;
