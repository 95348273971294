import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import { KokattoTicketTimeTrackingResponse } from "@/serviceClients/responses/KokattoTicketTimeTrackingResponse";
import { TicketTimeTrackingRequest } from "@/serviceClients/requests/KokattoTicketTimeTrackingRequest";
import { isKokattoSuccessResponse } from "@/serviceClients/responses/KokattoSuccessResponse";
import { KokattoTicketServiceClient } from "@/serviceClients/KokattoTicketServiceClient";

interface State {
  startTime: Date | null;
  endTime: Date | null;
}

const state: State = {
  startTime: null as Date | null,
  endTime: null as Date | null,
};

const getters: GetterTree<State, any> = {
  getDuration(state): number {
    if (state.startTime && state.endTime) {
      return Math.round(
        (state.endTime.getTime() - state.startTime.getTime()) / 1000
      );
    }
    return 0;
  },
};

const mutations: MutationTree<State> = {
  startTracking(state) {
    state.startTime = new Date();
  },
  stopTracking(state) {
    state.endTime = new Date();
  },
  resetTracking(state) {
    state.startTime = null;
    state.endTime = null;
  },
};

const actions: ActionTree<State, any> = {
  async submitDurationToBackend(
    { rootGetters, getters, commit },
    request: TicketTimeTrackingRequest,
    isRestart = true
  ) {
    commit("stopTracking");
    const duration = getters.getDuration;
    try {
      const response =
        await RefreshableRequestHelper.requestKokatto<KokattoTicketTimeTrackingResponse>(
          () => {
            const serviceClient = new KokattoTicketServiceClient({
              token: rootGetters.getKokattoTokenAccess,
            });
            return serviceClient.submitTicketDurationTracking({
              ...request,
              durationInSecond: duration,
            });
          }
        );

      if (isKokattoSuccessResponse(response)) {
        console.log("Duration submitted successfully");
      }

      commit("resetTracking");

      if (isRestart) commit("startTracking");
    } catch (error) {
      console.error("Error submitting duration:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
