const id = {
  shopifyTitle: "Shopify",
  shopifyAutomatedTextSetting: "Pengaturan Pesan Otomatis",
  shopifyReport: "Report",
  shopifyStoreConfiguration: "Konfigurasi Toko",
  shopifyConfigurationStoreHeader: "Akun Shopify",
  shopifyTableAccountName: "Nama Akun",
  shopifyTableStatus: "Status",
  shopifyTableAction: "Aksi",
  shopifyTableNoDataHeader: "Belum ada toko terhubung",
  shopifyTableNoDataDescription:
    "Hubungkan akun Shopify agar bisa mengirimkan pesan Whatsapp otomatis untuk informasi seputar pesanan kepada pelanggan.",
  shopifyButtonConnectStore: "Hubungkan Toko",
  shopifyFormUrlPlaceHolder: "Masukkan URL admin shopify kamu",
  shopifyFormUrlRequiredErrorMessage: "URL shopify wajib diisi",
  shopifyFormConnectButtonText: "Hubungkan",
  shopifyBannerInfo:
    "Akun toko Shopify belum tersambung, sambungkan melalui konfigurasi toko",
  shopifyConnectFormSuccessSnackbar: "Berhasil menghubungkan akun {0}",
  shopifyConnectFormErrorInvalidNameSnackbar:
    "Gagal menghubungkan akun. Cek lagi username atau URL akun kamu untuk menghubungkan",
  shopifyConnectFormErrorGeneralSnackbar:
    "Terjadi kesalahan, silakan coba lagi",
  shopifyConnectFormErrorInvalidToken:
    "Gagal menghubungkan akun, token invalid. Silakan coba lagi",
  shopifyConnectInstructionFirst:
    "Login ke akun admin Shopify melalui url <a class='font-weight-bold' target='_blank' href='https://admin.shopify.com'>https://admin.shopify.com/<a>.",
  shopifyConnectInstructionSecond:
    "Setelah berhasil login, silahkan copy url toko pada halaman browser. Contoh URL toko: <b>admin.shopify.com/store/(nama toko)</b> dan sambungkan di Koala+.",
  shopifyConnectDialogTitle: "Hubungkan akun Shopify",
  shopifyConnectFormUrlTitle: "URL admin Shopify",
  shopifyHowToConnectUrl: "Bagaimana cara mengetahui url halaman Shopify?",
  shopifyFormDialogFooter:
    "Koala+ menggunakan API resmi platform terkait, sehingga datamu terjamin 100% aman.",
  shopifyToggleButtonDisabledTooltip:
    "Tidak bisa mengaktifkan fitur. Hubungkan toko terlebih dahulu",
  shopifyToggleButtonNonActiveTooltip: "Nonaktifkan {0}",
  shopifyToggleButtonActiveTooltip: "Aktifkan {0}",
  shopifyStatusActive: "Aktif",
  shopifyStatusNonActive: "Non aktif",
  shopifySeeDetail: "Lihat detail",
  shopifyAutomaticMessageMessageConfirmedTitle:
    "Pesanan selesai / Telah terkonfirmasi",
  shopifyAutomaticMessageMessageConfirmedDescription:
    "Kirim pesan otomatis whatsapp kepada pelanggan segera setelah pelanggan melakukan pembelian ditoko.",
  shopifyAutomaticMessageOrderCreatedTitle: "Pesanan dibuat",
  shopifyAutomaticMessageOrderCreatedDescription:
    "Kirim pesan otomatis whatsapp kepada pelanggan segera setelah pelanggan membuat pesanan baru di toko kamu.",
  shopifyAutomaticMessageOrderSentWithTrackerCourierTitle:
    "Pesanan dikirim menggunakan pelacakan kurir",
  shopifyAutomaticMessageOrderSentWithTrackerCourierDescription:
    "Kirim pesan otomatis whatsapp kepada pelanggan segera jika pesanan sudah dalam proses pengiriman melalui kurir.",
  shopifyAutomaticMessageRefundTitle: "Pesan notifikasi refund",
  shopifyAutomaticMessageRefundDescription:
    "Kirim pesan otomatis whatsapp kepada pelanggan yang mengajukan refund.",
  shopifyFormActivate: "Aktifkan",
  shopifyFormNonActivate: "Non-aktifkan",
  shopifyFormSelectTemplatePlaceholder: "Pilih template",
  shopifyFormErrorReqiuredSelectTemplate: "Wajib pilih template",
  shopifyUpdateActivateWebhookSuccessSnackbar: "Berhasil mengaktifkan template",
  shopifyUpdateNonActivateWebhookSuccessSnackbar:
    "Berhasil menonaktifkan template",
  shopifyDeleteConfigSuccessSnackbar: "Berhasil menghapus akun",
  shopifyDeleteConfigFailedSnackbar: "Gagal menghapus akun",
  shopifyDeleteDialogDescription:
    "Jika akun dihapus, pesan notifikasi Shopify akan berhenti.",
  shopifyStatusPending: "Menunggu",
  shopifyStatusUninstalled: "Tidak terhubung",
  shopifyStatusConnected: "Terhubung",
  shopifyGetTemplateFailedSnackbar:
    "Gagal mendapatkan template. Silakan coba lagi.",
};

const en = {
  shopifyTitle: "Shopify",
  shopifyAutomatedTextSetting: "Automatic Message Setting",
  shopifyReport: "Report",
  shopifyStoreConfiguration: "Store Configuration",
  shopifyConfigurationStoreHeader: "Shopify Account",
  shopifyTableAccountName: "Account Name",
  shopifyTableStatus: "Status",
  shopifyTableAction: "Action",
  shopifyTableNoDataHeader: "There is no connected store yet",
  shopifyTableNoDataDescription:
    "Connect your Shopify account so you can send automatic WhatsApp messages for information about orders to customers.",
  shopifyFormUrlPlaceHolder: "Enter your Shopify admin URL",
  shopifyFormUrlRequiredErrorMessage: "Shopify URL is required",
  shopifyFormConnectButtonText: "Connect",
  shopifyBannerInfo:
    "Shopify account is not yet connected, connect via store configuration",
  shopifyButtonConnectStore: "Connect with Shopify",
  shopifyConnectFormSuccessSnackbar: "Account {0} is connected successfully",
  shopifyConnectFormErrorInvalidNameSnackbar:
    "Failed to connect account. Check your username or store URL again to connect",
  shopifyConnectFormErrorGeneralSnackbar:
    "Something went wrong. Please try again.",
  shopifyConnectFormErrorInvalidToken:
    "Failed to connect account, invalid token. Please try again.",
  shopifyConnectInstructionFirst:
    "Login to Shopify admin account via url <a class='font-weight-bold' target='_blank' href='https://admin.shopify.com'>https://admin.shopify.com/<a>.",
  shopifyConnectInstructionSecond:
    "After successfully logging in, please copy the store's URL on the browser page. Example store's URL: <b>admin.shopify.com/store/(store name)</b> and connect it in Koala+.",
  shopifyConnectDialogTitle: "Connect your Shopify Account",
  shopifyConnectFormUrlTitle: "Shopify admin's URL",
  shopifyHowToConnectUrl: "How to get Shopify page url?",
  shopifyFormDialogFooter:
    "Koala+ uses the official API of the relevant platform, so your data is 100% securely protected.",
  shopifyToggleButtonDisabledTooltip:
    "Feature can't be activate. Connect the store first",
  shopifyToggleButtonNonActiveTooltip: "Disable {0}",
  shopifyToggleButtonActiveTooltip: "Enable {0}",
  shopifyStatusActive: "Active",
  shopifyStatusNonActive: "Non active",
  shopifySeeDetail: "See detail",
  shopifyAutomaticMessageMessageConfirmedTitle: "Order completed / Confirmed",
  shopifyAutomaticMessageMessageConfirmedDescription:
    "Send automatic WhatsApp messages to customers as soon as the customer makes a purchase at the store.",
  shopifyAutomaticMessageOrderCreatedTitle: "Order created",
  shopifyAutomaticMessageOrderCreatedDescription:
    "Send automatic WhatsApp messages to customers as soon as the customer makes a new order at your store.",
  shopifyAutomaticMessageOrderSentWithTrackerCourierTitle:
    "Orders are shipped using courier tracking",
  shopifyAutomaticMessageOrderSentWithTrackerCourierDescription:
    "Send an automatic WhatsApp message to the customer immediately if the order is in the process of being sent via courier.",
  shopifyAutomaticMessageRefundTitle: "Refund notification",
  shopifyAutomaticMessageRefundDescription:
    "Send an automatic WhatsApp message to the customer who applies for a refund.",
  shopifyFormActivate: "Activate",
  shopifyFormNonActivate: "Non-activate",
  shopifyFormSelectTemplatePlaceholder: "Select template",
  shopifyFormErrorReqiuredSelectTemplate: "Template is required",
  shopifyUpdateActivateWebhookSuccessSnackbar:
    "Template activated successfully",
  shopifyUpdateNonActivateWebhookSuccessSnackbar:
    "Template non-activated successfully",
  shopifyDeleteConfigSuccessSnackbar: "Account successfully removed",
  shopifyDeleteConfigFailedSnackbar: "Failed to delete account",
  shopifyDeleteDialogDescription:
    "If account deleted, the automation notification Shopify will be disable.",
  shopifyStatusPending: "Pending",
  shopifyStatusUninstalled: "Disconnected",
  shopifyStatusConnected: "Connected",
  shopifyGetTemplateFailedSnackbar:
    "Failed to get template list. Please try again.",
};

export default { id, en };
